@import url("https://fonts.googleapis.com/css?family=Montserrat:200,700")

*
  font-family: 'Montserrat'
  color: #333333
  font-size: 14px
  font-weight: 200
  box-sizing: content-box
  margin: 0
  padding: 0
  outline: none

body
  display: block
  height: 100%
  background-color: #eaeaea

  form
    margin: 100px auto
    padding: 20px 40px
    width: 300px
    display: block
    background-color: #dfdfdf

    h1
      margin: 5px 0 20px 0
      font-size: 16px
      font-weight: 700

    .error
      color: red
      font-size: 12px
      letter-spacing: 1px
      font-weight: 700

    label
      display: block
      margin: 0 0 2px 0

    input
      display: block
      width: 280px
      margin: 0 0 10px 0
      border: 1px solid #cacaca
      padding: 5px 10px

    button
      display: inline-block
      margin: 10px 0
      padding: 5px 20px
      background-color: #eaeaea
      border: 1px solid #cacaca
      cursor: pointer

      &:hover
        color: #000
        background-color: #cacaca
        border: 1px solid #eaeaea

